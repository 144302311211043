<template>
<div class="row">
   <div class="col-xs-12 col-9 panel-group">
      <div class="box box-info">
         <div class="box-header with-border" v-on:click="toggleSegmentCollapsedState('1')">
            <h3 class="box-title">Contact Information</h3>
            <span aria-hidden="true" class="fa fa-angle-right pull-right expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment1}"></span>
         </div>
         <div class="box-body" v-bind:class = "segment1?'in collapse':''">
            <div class="row">
               <div class="col-xs-12 col-6">
                  <div>
                     <div class="form-group floating-label">
                        <input type="text" value="">
                        <label>First Name</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label">
                        <input value="" type="text">
                        <label>Middle Initial</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label">
                        <input type="text" value="">
                        <label>Last Name</label>
                     </div>
                  </div>
                  <div class="form-group floating-label multiple">
                     <label>Phone</label>
                     <div class="float-btn-group">
                        <button class="margin-5 icon-button button-delete" type="button">
                        <i class="fa fa-times-circle"></i>
                        </button>
                        <select>
                           <option label="Fax" value="string:Fax" selected="selected">Fax</option>
                           <option label="Home" value="string:Home">Home</option>
                           <option label="Mobile" value="string:Mobile">Mobile</option>
                           <option label="Work" value="string:Work">Work</option>
                        </select>
                        <input value="" type="text">
                     </div>
                     <button class="margin-5 icon-button button-add-row" type="button" ng-show="!property.isReadOnly" ng-click="addValue(property)">
                     <i class="fa fa-plus-circle"></i>
                     </button>
                  </div>
                  <div>
                     <div class="form-group floating-label multiple">
                        <label>Address</label>
                        <div class="float-btn-group">
                           <button class="margin-5 icon-button button-delete" type="button">
                           <i class="fa fa-times-circle"></i>
                           </button>
                           <select>
                              <option label="Home" value="string:Home" selected="selected">Home</option>
                              <option label="Work" value="string:Work">Work</option>
                           </select>
                           <textarea ng-model="value.value"></textarea>
                        </div>
                        <button class="margin-5 icon-button button-add-row" type="button" ng-show="!property.isReadOnly" ng-click="addValue(property)">
                        <i class="fa fa-plus-circle"></i>
                        </button>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label multiple">
                        <label>Customer Type</label>
                        <div class="float-btn-group">
                           <button class="margin-5 icon-button button-delete" type="button">
                           <i class="fa fa-times-circle"></i>
                           </button>
                           <select>
                              <option label="Agent" value="string:Agent" selected="selected">Agent</option>
                              <option label="Customer" value="string:Customer">Customer</option>
                              <option label="Spam" value="string:Spam">Spam</option>
                              <option label="Vendor" value="string:Vendor">Vendor</option>
                              <option label="-- select a value --" value="string:">-- select a value --</option>
                           </select>
                        </div>
                        <button class="margin-5 icon-button button-add-row" type="button" ng-show="!property.isReadOnly" ng-click="addValue(property)">
                        <i class="fa fa-plus-circle"></i>
                        </button>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label">
                        <input type="text" value="">
                        <label>Company</label>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-6">
                  <div class="ng-scope">
                     <div class="form-group floating-label ng-scope property-job-title">
                        <input type="text" value="">
                        <label class="ng-binding">Job Title</label>
                     </div>
                  </div>
                  <div class="ng-scope">
                     <div class="form-group floating-label group-bg detail-g-borderbottom">
                        <input type="text" value="">
                        <label class="ng-binding">Address1</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label group-bg detail-g-borderbottom detail-g-bordertop">
                        <input type="text" value="">
                        <label class="ng-binding">Address2</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label group-bg detail-g-borderbottom detail-g-bordertop">
                        <input type="text" value="">
                        <label class="ng-binding">City</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label group-bg detail-g-borderbottom detail-g-bordertop">
                        <input type="text" value="">
                        <label class="ng-binding">State</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label group-bg detail-g-borderbottom detail-g-bordertop">
                        <input type="text" value="">
                        <label class="ng-binding">Postal Code</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label group-bg detail-g-bordertop">
                        <input type="text" value="">
                        <label class="ng-binding">Country</label>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               <div class="form-group floating-label multiple">
                  <label>Logins</label>
                  <div class="float-btn-group">
                     <button class="margin-5 icon-button button-delete" type="button">
                     <i class="fa fa-times-circle"></i>
                     </button>
                     <select>
                        <option value="Email" selected="selected">Email</option>
                        <option value="Bounced">Bounced</option>
                        <option value=""></option>
                     </select>
                     <span class="ng-binding">Email</span>
                     <input type="text" value="">
                  </div>
                  <div class="float-btn-group">
                     <button class="margin-5 icon-button button-delete" type="button">
                     <i class="fa fa-times-circle"></i>
                     </button>
                     <select>
                        <option value="Email" selected="selected">Email</option>
                        <option value="Bounced">Bounced</option>
                        <option value=""></option>
                     </select>
                     <input type="text" value="">
                  </div>
                  <button class="margin-5 icon-button button-add-row" type="button">
                  <i class="fa fa-plus-circle"></i>
                  </button>
               </div>
            </div>
         </div>
      
      </div>
      <div class="box box-warning">
         <div class="box-header with-border" v-on:click="toggleSegmentCollapsedState('2')">
            <h3 class="box-title ng-binding">Customer Service</h3>
            <span aria-hidden="true" class="fa fa-angle-right pull-right    expanded" v-bind:class = "segment2?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment2}"></span>
         </div>
         <div class="box-body" v-bind:class = "segment2?'in collapse':''">
            <div class="row">
               <div class="col-xs-12 col-6">
                  <div>
                     <div class="form-group floating-label property-contact-property-1">
                        <input type="text" value="">
                        <label>Contact Property 1</label>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-6">
               </div>
            </div>
            <div>
              
            </div>
         </div>
      
      </div>
      <div class="box box-success">
         <div class="box-header with-border" v-on:click="toggleSegmentCollapsedState('3')">
            <h3 class="box-title ng-binding">Sales</h3>
            <span aria-hidden="true" class="fa fa-angle-right pull-right    expanded" v-bind:class = "segment3?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment3}"></span>
         </div>
         <div class="box-body" v-bind:class = "segment3?'in collapse':''">
            <div class="row">
               <div class="col-xs-12 col-6">
                  <div>
                     <div class="form-group floating-label property-text">
                        <input type="text" value="">
                        <label>Text</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label property-date">
                        <input type="text" value="">
                        <label>Date</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label property-additional-detail">
                        <select class="">
                           <option label="detail1a" value="string:detail1a">detail1a</option>
                           <option label="detail1b" value="string:detail1b">detail1b</option>
                           <option label="detail1c" value="string:detail1c">detail1c</option>
                           <option label="-- select a value --" value="string:" selected="selected">-- select a value --</option>
                        </select>
                        <label>Additional Detail</label>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-6">
                  <div>
                     <div class="form-group floating-label property-existing-phone-property">
                        <select class="">
                           <option value="string:"></option>
                           <option label="-- select a value --" value="string:" selected="selected">-- select a value --</option>
                        </select>
                        <label>Existing Phone Property</label>
                     </div>
                  </div>
                  <div>
                     <div class="form-group floating-label property-svc-level-5-min">
                        <select class="">
                           <option label="False" value="string:False">False</option>
                           <option label="True" value="string:True">True</option>
                           <option label="-- select a value --" value="string:" selected="selected">-- select a value --</option>
                        </select>
                        <label>Svc Level 5 Min</label>
                     </div>
                  </div>
               </div>
            </div>
            <div>
               
            </div>
         </div>
         
      </div>
      <div class="box box-danger">
         <div class="box-header with-border" v-on:click="toggleSegmentCollapsedState('4')">
            <h3 class="box-title ng-binding">Human Resources</h3>
            <span aria-hidden="true" class="fa fa-angle-right pull-right    expanded" v-bind:class = "segment4?'':'chevron-expand-indicator_expanded'" ng-class="{'': !segment4}"></span>
         </div>
         <div class="box-body" v-bind:class = "segment4?'in collapse':''">
            <div class="row">
               <div class="col-xs-12 col-6">
                  <div>
                     <div class="form-group floating-label property-s2only">
                        <input type="text" value="">
                        <label>s2only</label>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-6">
               </div>
            </div>
            <div>
            
            </div>
         </div>

      </div>
   </div>
   <div class="col-xs-12 col-3">
      <h3>Segment Membership 
        <span  v-tooltip="{text: 'Each contact must be a member of at least one segment, which determines the agents that can view their information.',theme: {placement: 'left', }}">
        <i class="fa fa-question-circle text-info help-icon"></i>
        </span>
      </h3>
      <div class="segment">
         <label>
         <input type="checkbox" class="form-check-input" checked>
         Customer Service
         </label>
      </div>
      <div class="segment">
         <label>
         <input type="checkbox" class="form-check-input" checked>
         Sales
         </label>
      </div>
      <div class="segment">
         <label>
         <input type="checkbox" class="form-check-input" checked>
         Human Resources
         </label>
      </div>
   </div>
</div>
<button class="btn btn-primary" type="button" ng-click="">
Save
</button>
</template>

<script>
export default {
  name: 'ContactInformationComponents',
   components: {
  },
  data: function(){
      return{
        segment1 : false,
        segment2 : false,
        segment3 : false,
        segment4 : false
      }
         
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>