<template>
    <Breadcrumb page-title="Goley Lookup Table" page-section="Goley Inc."> </Breadcrumb>
     <section class="content">
       <div class="box box-primary">
           <div class="box-header">
               
            </div>
            <div class="box-body">
                  <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label text-left">Table Name</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label text-left">Price</label>
                            <input iservice-id="Name" type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <button class="btn btn-lg btn-link pt-4" type="button" title="Remove Table"><i class="fa fa-times-circle text-red"></i></button>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <button class="btn btn-lg btn-link" type="button">
                        <i class="fa fa-plus-circle text-light-blue"></i> Add Column Defination
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-1">
                        <div class="form-group">
                            <label class="control-label text-left">#</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                           Type
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                          Lable
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-1">
                        <div class="form-group">
                            <label class="control-label text-left">Col1</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <select class="form-control">
                                <option>Number</option>                               
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-1">
                        <div class="form-group">
                            <label class="control-label text-left">Col2</label>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <select class="form-control">
                                <option>Text</option>                               
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button class="btn btn-lg btn-link" type="button" title="Remove Field"><i class="fa fa-times-circle text-red"></i></button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                        <label class="form-label" for="customFile">Upload file</label>
                        <div class="d-flex">
                        <input type="file" class="form-control" id="customFile" /> 
                        <button class="btn btn-primary">Upload</button>
                        </div>
                    </div>
                  </div>

                  <div class="row  mtop-15"><div class="col-2"><button iservice-id="Save" class="btn btn-primary">Save</button></div><div class="col-10 admin-error"><!--v-if--></div></div>
                  <div class="row">
                    <div class="col-md-12">
                        <h3>Table Preview
                            </h3>
                        <div class="estimator-row mtop-15"> 						
                        <div class="p-datatable p-component p-datatable-responsive-scroll">
                          <div class="p-datatable-wrapper table-responsive">
                              <table class="p-datatable-table editable-cells-table">
                                <thead class="p-datatable-thead">
                                  <tr>
                                    <th>Material</th>
                                    <th>Price</th>
                                    <th>SKU</th>
                                    </tr>
                                </thead>
                                <thead class="p-datatable-tbody">
                                
                                    <tr>
                                        <td>Demo</td>
                                        <td>$600dd</td>
                                        <td>Ufoop</td>
                                    </tr> 
                                    <tr>
                                        <td>Option 1</td>
                                        <td>$300</td>
                                        <td>U89</td>
                                    </tr> 
                                    <tr>
                                        <td>Option 2</td>
                                        <td>$500</td>
                                        <td>X8989</td>
                                    </tr> 
                                    <tr>
                                        <td>Option 3</td>
                                        <td>$200</td>
                                        <td>U00</td>
                                    </tr>                            
                                </thead>
                              </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
     </section>
   </template>
   
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
   import ContactInformation from '../components/ContactInformation.vue';
   
   export default {
     name: 'Lookup table',
     components: {
       Breadcrumb,
       ContactInformation
     },
     ready () {
       $(document).ready(function ($) {
       })
     }
   }
   </script>